.label {
    @apply w-full max-w-[110px] flex items-center text-sm font-semibold mb-2 sm:mb-0;
}

.employee-input {
    @apply w-full max-w-[400px] bg-white;
}

.form-row-input {
    @apply flex flex-col sm:flex-row mb-3 sm:mb-2;
}

.rnc__notification {
    @apply rounded-xl !w-[370px];
}

.rnc__notification-content {
    @apply px-4 py-2 w-full;
}

.rnc__notification-item--danger {
    @apply bg-errorscale-light border-l-0 rounded-xl;
}

.rnc__notification-item--danger .rnc__notification-content .rnc__notification-timer {
    @apply !bg-errorscale-light !h-0;
}

.rnc__notification-item--danger .rnc__notification-content .rnc__notification-title {
    @apply text-base font-semibold text-black;
}

.rnc__notification-item--success {
    @apply bg-successscale-light border-l-0;
}

.rnc__notification-item--success .rnc__notification-content .rnc__notification-timer {
    @apply !bg-successscale-light !h-0;
}

.rnc__notification-item--success .rnc__notification-content .rnc__notification-title {
    @apply text-base font-semibold text-black;
}