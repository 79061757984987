table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
    border-color: 'red';
}

table tr:first-child td:first-child {
    border-top-left-radius: 8px;
    border-color: 'red';
}

table tr:first-child td:last-child {
    border-top-right-radius: 8px;
}
    
table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}