.container {
    @apply h-24 w-24;
  position: relative;
}

.image {
    @apply h-24 w-24;
  opacity: 1;
  backface-visibility: hidden;
}

.middle {
    @apply absolute top-0 bottom-0 left-0 right-0 h-full w-full opacity-20 bg-black flex flex-col text-white;
}

.container:hover .image {
  opacity: 0.1;
}

.text {
  color: white;
}
