.filter-anchor {
  @apply flex items-center justify-between px-4 py-2 rounded-md border h-[37px] text-sm bg-[#EFF0F6];
  @apply hover:shadow-dropdown hover:ring-[0.5px] hover:ring-primary-dark;
}

.anchor-label {
  @apply w-full truncate text-left;
}

.avatar-wrapper {
  @apply w-[26px] h-[26px] rounded-full ml-3 overflow-hidden;
}
