.mui-grid-searchbar {
    @apply !py-2 !my-2 !ml-4 border-0 !text-greyscale-darkGrey !border-none !border-b-0 rounded-lg !w-[417px];
}

.Mui-focused:after {
    @apply !border-none;
}

.Mui-focused:before {
    @apply !border-none;
}

.MuiInput-underline:before {
    @apply !border-none;
}

.MuiInput-underline:after {
    @apply !border-none;
}

.MuiInput-underline {
    @apply ml-5 mr-4;
}

.rnc__notification {
    @apply rounded-xl !w-[370px];
}

.rnc__notification-content {
    @apply px-4 py-2 w-full;
}

.rnc__notification-item--danger {
    @apply bg-errorscale-light border-l-0 rounded-xl;
}

.rnc__notification-item--danger .rnc__notification-content .rnc__notification-timer {
    @apply !bg-errorscale-light !h-0;
}

.rnc__notification-item--danger .rnc__notification-content .rnc__notification-title {
    @apply text-base font-semibold text-black;
}

.rnc__notification-item--success {
    @apply bg-successscale-light border-l-0;
}

.rnc__notification-item--success .rnc__notification-content .rnc__notification-timer {
    @apply !bg-successscale-light !h-0;
}

.rnc__notification-item--success .rnc__notification-content .rnc__notification-title {
    @apply font-normal text-greyscale-black;
}

.MuiDataGrid-filterFormDeleteIcon {
    @apply bg-[#EFF0F6] !text-black rounded-lg w-[40px] h-[40px] !flex !justify-center !items-center !mr-3;  
}

.MuiDataGrid-panelFooter {
    @apply !justify-start !gap-1 flex-row-reverse;
}

.MuiDataGrid-panelFooter:first-child {
    @apply !bg-[#EFEFEF];
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium {
    @apply !text-black bg-ctascale-lightMain active:shadow-activeButton active:bg-ctascale-lightMain disabled:!bg-[#9B9B9B] px-6 py-3 mb-2 mr-3 rounded-lg !normal-case;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium:nth-of-type(2) {
    @apply !bg-[#EFEFEF];
}

.MuiInput-underline {
    @apply ml-0 mr-0 !w-full;
}

.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
    @apply ml-2 mr-4 mt-1;
}

.MuiDataGrid-columnsPanelRow {
    @apply mt-0.5;
}

.MuiDataGrid-panelHeader {
    @apply !p-0;
}

.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
    @apply bg-greyscale-offWhite w-11/12;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd {
    @apply !w-11/12 ml-3 !rounded-lg p-[2px];
}

.MuiSwitch-root.MuiSwitch-sizeSmall .Mui-checked {
    @apply text-ctascale-darkMain;
}

.MuiSwitch-root.MuiSwitch-sizeSmall .MuiSwitch-track {
    @apply !bg-ctascale-lightMain;
}