.modal {
  @apply fixed z-50 right-0 bottom-0 top-0 left-0 flex items-center justify-center p-4 overflow-y-auto;
}

.MuiBackdrop-open {
  @apply fixed right-0 bottom-0 top-0 left-0 backdrop-blur-[5px];
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
  @apply max-w-[855px] max-h-full w-full rounded-lg p-9 bg-white focus:outline-0 overflow-auto;
}
