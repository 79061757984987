@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');

@font-face {
  font-family: 'Inter';
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  @apply text-secondary;
  font-family: Inter;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @apply bg-[#f5f5f5];
}

code {
  font-family: 'Inter', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  @apply text-sm hover:cursor-pointer;
}

.bg-beautiful {
  background-color: #76FD80;
}

.break-word {
  word-break: break-word;
}


::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-track {
  @apply bg-greyscale-lightGrey rounded-lg;
}

::-webkit-scrollbar-thumb {
  @apply rounded-lg bg-greyscale-darkGrey;
}

::-webkit-scrollbar-thumb:hover {
  @apply rounded-lg bg-greyscale-darkGrey;
}

.rpv-default-layout__sidebar-tabs{
  display: none;
}

.rpv-toolbar__left {
  display: none;
}

.rpv-toolbar__right {
  display: none;
}