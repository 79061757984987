.custom-switch.MuiSwitch-root {
  @apply text-[0px] relative inline-block w-14 h-8 cursor-pointer;
}

.custom-switch .MuiSwitch-track {
  @apply rounded-2xl block h-full w-full absolute;
}

.isEditScreen .custom-switch .MuiSwitch-track {
  @apply bg-ctascale-supportMain;
}

.isNotEditScreen .custom-switch .MuiSwitch-track {
  @apply bg-greyscale-greyishWhite;
}

.custom-switch .MuiSwitch-thumb {
  @apply block w-7 h-7 top-0.5 left-0.5 rounded-full bg-white relative;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
}

.custom-switch.MuiSwitch-root.Mui-checked .MuiSwitch-thumb {
  @apply left-[27px] top-0.5 bg-white;
}

.isEditScreen .custom-switch.MuiSwitch-root.Mui-checked .MuiSwitch-track {
  @apply bg-ctascale-lightMain;
}

.isNotEditScreen .custom-switch.MuiSwitch-root.Mui-checked .MuiSwitch-track {
  @apply bg-[#716B70];
}

.custom-switch .MuiSwitch-input {
  @apply cursor-pointer absolute w-full h-full top-0 left-0 opacity-0 z-10 m-0;
}
