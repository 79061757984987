.checkbox {
  @apply relative cursor-pointer text-lg w-[18px] h-[18px] flex items-center;
}

.checkbox input:checked ~ .mark {
  @apply !bg-primary border-primary;
}

.mark:after {
  @apply absolute hidden content-[''];
}

.checkbox input:checked ~ .mark:after {
  @apply block;
}

.checkbox-input {
  @apply absolute opacity-0 cursor-pointer h-0 w-0;
}

.mark {
  @apply absolute top-0 left-0 w-[18px] h-[18px] border rounded-full border-gray-300;
}

.checkIcon::after {
  @apply transform rotate-45 left-[6px] top-[1.5px] w-[5px] h-[10px] border border-white;
  border-width: 0 2px 2px 0;
}

.minusIcon::after {
  @apply left-[3px] top-1.5 w-[10px] h-0.5 border-t-[2px] border-white;
}
