.input {
  @apply flex items-center rounded border h-[50px] text-sm px-2 py-3 border-primary;
}

.input.MuiInput-multiline {
  @apply h-[104px] p-0 overflow-hidden;
}

.input input,
.input textarea {
  @apply focus:outline-0 focus:border-0 flex-1 px-3 bg-transparent tracking-wider w-full;
}

.input textarea {
  @apply h-full py-2 px-4;
}

.input.Mui-focused {
  @apply ring-1 ring-primary;
}

.input.success {
  @apply border-successscale-medium;
}

.input.success.Mui-focused {
  @apply ring-1 ring-successscale-medium;
}

.input.error {
  @apply border-0 bg-errorscale-light;
}