.filter-anchor {
  @apply flex items-center justify-between px-4 py-2 rounded-md border text-sm bg-[#EFF0F6];
  @apply hover:shadow-dropdown hover:ring-[0.5px] hover:ring-primary-dark;
}

.editEmployee-input {
  @apply w-full !bg-greyscale-greyishWhite disabled:!bg-greyscale-offWhite flex items-center h-[57px] text-sm px-2 py-3 rounded-xl !outline-none focus:outline-none;
}

.anchor-label {
  @apply w-full truncate text-left;
}

.avatar-wrapper {
  @apply w-[26px] h-[26px] rounded-full ml-3 overflow-hidden;
}
