.button {
  @apply rounded-lg font-semibold py-3 px-6 flex items-center justify-center hover:shadow-button;
}

.button.primary {
  @apply text-white  active:shadow-activeButton active:bg-primary disabled:!bg-[#9B9B9B];
}

.button.secondary {
  @apply border border-secondary !bg-secondary text-white disabled:text-grey disabled:opacity-50 active:border-primary;
}

.button.bg-beautiful {
  @apply text-black !bg-ctascale-lightMain active:shadow-activeButton active:bg-ctascale-lightMain disabled:opacity-50;
}

.button.bg-muted {
  @apply text-black !bg-zinc-100 active:shadow-activeButton active:bg-zinc-100 disabled:opacity-50;
}

.button.danger {
  @apply text-white !bg-rose-800 active:shadow-activeButton text-center text-neutral-50 text-base font-semibold font-['Inter'] leading-normal tracking-wide disabled:opacity-50;
}