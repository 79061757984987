.auth-layout {
  @apply min-h-[100vh] py-6 px-6 md:py-[1.875rem] md:px-[3.75rem] flex flex-col;
  background-size: cover;
  background-color: #efefef; 
  background-image: url('/src/assets/images/auth_bg.png');
  background-position: top left;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .auth-layout {
    background-image: none;
  }
}
