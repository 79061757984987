.sidebar {
  @apply w-[210px] bg-white pt-[50px] pb-6 flex flex-col relative rounded-br-2xl h-full;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.collapsed {
  @apply flex items-center w-full;
}

.collapsed-bg {
  @apply w-full backdrop-blur-[5px] z-50;
  background-color: rgba(0, 0, 0, 0.5);
}

.collapsed .collapse-button {
  @apply rotate-[180deg];
}

.collapsed p {
  @apply hidden;
}

.collapse-button {
  @apply w-10 h-10 !rounded-lg text-black flex justify-center items-center absolute !bottom-[19vh] -right-2 hover:cursor-pointer z-50 bg-ctascale-lightMain;
  transition: 0.3s;
}
